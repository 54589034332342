import "./add_user.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import React, { useState, useEffect } from "react";
import { addDoc, serverTimestamp, collection } from "firebase/firestore";
import { db, storage } from "./firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { IonIcon } from "@ionic/react";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./add_user.css";
import { Alert, Button } from "flowbite-react";
import { HiInformationCircle } from "react-icons/hi";
import Header from "./Header";
import MySidebar from "./MySidebar";

const AddUser = () => {
  const [file, setFile] = useState("");
  const [per, setPerc] = useState(null);

  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password:'',
    date: Date.now(),
  });

  const handleClear = () => {
    user.first_name = "";
    user.last_name = "";
    user.email = "";
    user.password = "";
  };

 
  const handleChange = (value) => {
    setUser({ ...user, content: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "user"), {
        ...user,
        timeStamp: serverTimestamp(),
      });
    } catch (error) {
      console.log("error :" + error.serverResponse);
      setOpenError(true);
    }
    handleClear();
    setOpenSuccess(true);
  };

  useEffect(() => {
    const uploadFile = () => {
      const name = new Date().getTime() + file.name;

      console.log(name);
      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setPerc(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setUser((prev) => ({ ...prev, img: downloadURL }));
          });
        }
      );
    };
    file && uploadFile();
    console.log("img");
  }, [file]);

  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);

  return (
    <div className=" add-news-body ">
     <Header loc="Add User" />
      <div className="container">
      <div className="sidebar" >
        <MySidebar loc='add_user'/>
      </div>
      <form action="" method="post">
       
      {openSuccess && (
        <Alert
          color="success"
          className="alert"
          style={{ width: "30%", height:'5vh' }}
          
        >
         <center>
            Data Added Successfully!
         </center>
        </Alert>
      )}

      {openError && (
        <Alert
          color="failure"
          className="alert"
          icon={HiInformationCircle}
          style={{ width: "30%", height:'5vh' }}
        >
         <center>
          Failed To Add!
         </center>
        </Alert>
      )}
        
        <center>
          <input
            className="w-80 mb-2 p-2 textfield"
            placeholder="First Name"
            id="outlined-size-small"
            size="small"
            type="text"
            onChange={(e) => setUser({ ...user, first_name: e.target.value })}
          />
        </center>
        <center>
          <input
            className="w-80 mb-2 p-2 textfield"
            placeholder="Last Name"
            id="outlined-size-small"
            size="small"
            type="text"
            onChange={(e) => setUser({ ...user, last_name: e.target.value })}
          />
        </center>
        <center>
          <input
            className="w-80 mb-2 p-2 textfield"
            placeholder="Email"
            id="outlined-size-small"
            size="small"
            type="email"
            onChange={(e) => setUser({ ...user, email: e.target.value })}
          />
        </center>
        <center>
          <input
            className="w-80 mb-2 p-2 textfield"
            placeholder="Password"
            id="outlined-size-small"
            size="small"
            type="password"
            onChange={(e) => setUser({ ...user, password: e.target.value })}
          />
        </center>
        
       
        <div className="row">
          <Button className="btn" onClick={handleSubmit}>
            Save
          </Button>
         
        </div>
      </form>

    </div>
    </div>
  );
};

export default AddUser;
