import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDNZhr8Or4L8iPEC5vJckVn5fhWWC6n--w",
  authDomain: "path-99983.firebaseapp.com",
  projectId: "path-99983",
  storageBucket: "path-99983.appspot.com",
  messagingSenderId: "810779527055",
  appId: "1:810779527055:web:50f7b2ad7a9ab9b44dadfa",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth();
