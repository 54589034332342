"use client";

import React, { useEffect, useState } from "react";
import "./news.css";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { useLocation, useNavigate } from "react-router-dom";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { serverTimestamp } from "firebase/firestore";
import { db, storage } from "./firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { deleteDoc } from "firebase/firestore";
import { doc, updateDoc } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Modal } from "flowbite-react";
import { faUpload, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import MySidebar from "./MySidebar";
import Header from "./Header";

const News = () => {
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [news, setNews] = useState([]);

  useEffect(() => {
    const q = query(collection(db, "news"));
    const unsub = onSnapshot(
      q,
      (snapShot) => {
        let list = [];
        snapShot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        list.sort((a, b) => parseFloat(a.timeStamp) - parseFloat(b.timeStamp));
        setNews(list);
        console.log(list);
      },
      (error) => {
        console.log(error);
      },
      []
    );

    return () => {
      unsub();
    };
  }, []);

  const navigate = useNavigate();

  const handleDetail = (row) => {
    navigate("/detail", { state: { ...row } });
  };

  const handleDeleted = async () => {
    const deleteRef = doc(db, "news", id);
    await deleteDoc(deleteRef);
    handleClose_1();
  };

  const [id, setId] = useState("");

  const [data, setData] = useState({});
  const [per, setPerc] = useState(null);
  const [file, setFile] = useState("");

  useEffect(() => {
    const uploadFile = () => {
      const name = new Date().getTime() + file.name;

      console.log(name);
      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setPerc(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setNewsData((prev) => ({ ...prev, img: downloadURL }));
          });
        }
      );
    };
    file && uploadFile();
  }, [file]);

  const handleUpdate = async () => {
    const updateRef = doc(db, "news", newsData.id);
    await updateDoc(updateRef, {
      ...newsData,
      lastUpdated: serverTimestamp(),
    });
    handleClose();
  };

  const [newsData, setNewsData] = useState({
    title: "",
    snippet: "",
    content: "",
    img: "",
  });

  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "link"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        [{ align: [] }],
        ["clean"],
        [{ color: [] }],
      ],
    },
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  const handleChange = (value) => {
    setNewsData({ ...newsData, content: value });
  };

  const [openModalUpdate, setOpenModalUpdate] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);

  const [open, setOpen] = useState(false);
  const handleOpen = (row) => {
    setNews(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [open_1, setOpen_1] = useState(false);

  const handleClose_1 = () => setOpen_1(false);

  const [show, setShow] = useState(true);
  const location = useLocation();
  useEffect(() => {
    if (location.pathname == "/") {
      setShow(false);
    }
  }, []);

  return (
    <div className="news-body">
      <Header loc="News & Blogs" />
      <div className="container">
        <div className="sidebar" >
          <MySidebar loc='news'/>
        </div>
        <section className="news" aria-labelledby="news-label">
          <ul className="grid-list bg-transparent" style={{ width: "82.4vw"}}>
            {news.map((n) => {
              return (
                <div>
                  <li key={n.id} style={{ width: "80%" }}>
                    <Card
                      className="max-w-sm news-card"
                      imgAlt="Meaningful alt text for an image that is not purely decorative"
                      imgSrc={n.img}
                      style={{ height: "10vh" }}
                    >
                      <h5
                        style={{ height: "10vh" }}
                        className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white"
                      >
                        {n.title}
                      </h5>
                      <p
                        style={{ height: "5vh", overflowY: "scroll" }}
                        className="font-normal text-gray-700 dark:text-gray-400"
                      >
                        {n.snippet}
                      </p>

                      <div
                        style={{ height: "5vh" }}
                        className="mt-4 flex space-x-4 lg:mt-6 "
                      >
                        <Button
                          style={{ bottom: "0" }}
                          onClick={(e) => {
                            setNewsData(n);
                            setOpenModalUpdate(true);
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          onClick={(e) => {
                            setId(n.id);
                            setOpenModalDelete(true);
                          }}
                        >
                          Delete
                        </Button>
                      </div>
                      <Button
                        style={{ height: "5vh" }}
                        onClick={(e) => handleDetail(n)}
                        className="btn"
                      >
                        Read more
                        <svg
                          className="-mr-1 ml-2 h-4 w-4"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </Button>
                    </Card>
                  </li>
                </div>
              );
            })}
          </ul>
        </section>

        <Modal show={openModalUpdate} onClose={() => setOpenModalUpdate(false)}>
          <Modal.Header>Edit</Modal.Header>
          <Modal.Body>
            <div className="space-y-6">
              <div>
                <div className="bottom">
                  <div className="mt-4 flex space-x-4 lg:mt-6 items-center">
                    <label
                      htmlFor="file"
                      className="mt-4 border border-cyan-700 h-8 inline-flex items-center rounded-lg p-4"
                    >
                      <FontAwesomeIcon
                        icon={faUpload}
                        color="#4d7386"
                        size="lg"
                      />
                    </label>
                    <img src={newsData.img} alt="" style={{ width: "20vw" }} />
                    <input
                      type="file"
                      id="file"
                      onChange={(e) => setFile(e.target.files[0])}
                      style={{ display: "none" }}
                    />
                  </div>
                </div>
              </div>
              <div>
                <input
                  className="w-100 mb-2 p-2"
                  placeholder="Title"
                  id="outlined-size-small"
                  size="small"
                  value={newsData.title}
                  onChange={(e) =>
                    setNewsData({ ...newsData, title: e.target.value })
                  }
                />
              </div>
              <div>
                <textarea
                  className="w-100 mb-2 p-2"
                  label="Snippet"
                  id="outlined-size-small"
                  size="small"
                  value={newsData.snippet}
                  onChange={(e) =>
                    setNewsData({ ...newsData, snippet: e.target.value })
                  }
                />
              </div>
              <div className="mt-3" >
                <center>
                  <ReactQuill
                    theme="snow"
                  
                    className="w-100 mb-2 p-2"
                    modules={modules}
                    value={newsData.content}
                    formats={formats}
                    onChange={handleChange}
                  />
                </center>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn"
              onClick={() => {
                handleUpdate();
                setOpenModalUpdate(false);
              }}
            >
              Save
            </Button>
            <Button
              className="btn"
              color="gray"
              onClick={() => setOpenModalUpdate(false)}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={openModalDelete}
          size="md"
          onClose={() => setOpenModalDelete(false)}
          popup
        >
          <Modal.Header />
          <Modal.Body>
            <div className="text-center">
              <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
              <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                Are you sure you want to delete this product?
              </h3>
              <div className="flex justify-center gap-4">
                <Button
                  color="failure"
                  onClick={() => {
                    handleDeleted();
                    setOpenModalDelete(false);
                  }}
                >
                  {"Yes, I'm sure"}
                </Button>
                <Button
                  color="gray"
                  onClick={() => {
                    setOpenModalDelete(false);
                  }}
                >
                  No, cancel
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default News;
