  import "./add_videos.css";
  import React, { useState, useEffect } from "react";
  import { addDoc, serverTimestamp, collection } from "firebase/firestore";
  import { db, storage } from "./firebase";
  import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
  import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
  import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { Alert } from "flowbite-react";
import { HiInformationCircle } from "react-icons/hi";
import Header from "./Header";
import MySidebar from "./MySidebar";
  
  const AddVideos = () => {
    const [file, setFile] = useState("");
    const [per, setPerc] = useState(null);
  
    const [videos, setVideos] = useState({
      link: "",
      description: "",
      date: Date.now()
    });
  
    const handleClear = () => {
      videos.link = "";
      videos.description = "";
    };
  
 
  
    const [message, setMessage] = useState("");
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        await addDoc(collection(db, "videos"), {
          ...videos,
          timeStamp: serverTimestamp(),
        });

      } catch (error) {
        console.log("error :" + error.serverResponse);
        setOpenError(true);
      }
      handleClear();
      setOpenSuccess(true);
    };
  
  
    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [openError, setOpenError] = React.useState(false);
  
    
  
   
  
    return (
      <div className=" add-videos-body ">
       
     <Header loc="Post Videos" />
      <div className="container">
      <div className="sidebar" >
        <MySidebar loc='add_video'/>
      </div>
        <form action="" method="post">
        {openSuccess && (
          <Alert
            color="success"
            className="alert"
            style={{ width: "30%", height:'5vh' }}
            
          >
           <center>
              Data Added Successfully!
           </center>
          </Alert>
        )}
  
        {openError && (
          <Alert
            color="failure"
            className="alert"
            icon={HiInformationCircle}
            style={{ width: "30%", height:'5vh' }}
          >
           <center>
            Failed To Add!
           </center>
          </Alert>
        )}
  
          <div>
            <input
              placeholder="Link"
              className="textfield"
              value={videos.link}
              onChange={(e) => setVideos({ ...videos, link: e.target.value })}
            />
          </div>
          <div>
            <textarea
              placeholder="Description"
              id="outlined-size-small"
              size="small"
              className="textfield"
              rows={2}
              style={{
                border: "1px solid grey",
                width: "60vw",
                height: "10vh",
                padding: "2%",
                marginBottom: "3%",
              }}
              value={videos.description}
              onChange={(e) => setVideos({ ...videos, description: e.target.value })}
            />
          </div>
          <div className="row">
            <button className="btn" onClick={handleSubmit}>Save</button>
            
          </div>
        </form>
        
        </div>
       
        </div>
    );
  };
  
  export default AddVideos;
  