

import './header.css'
export default function Header(props) {
  return (
    <div>
    <ul className='header'>
      <li style={{fontSize:'27px', color:'white', marginLeft:'5%', float:'left'}} className='mt-1'>{props.loc}</li>
     
    </ul>
  
    </div>
  );
}
