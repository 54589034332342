
'use client';

import { Sidebar } from 'flowbite-react';
import { HiArrowSmRight, HiChartPie, HiFolderAdd, HiInbox, HiLogout, HiNewspaper, HiOutlineNewspaper, HiOutlineVideoCamera, HiPlus, HiPlusCircle, HiPresentationChartBar, HiShoppingBag, HiTable, HiUser, HiUserAdd, HiVideoCamera } from 'react-icons/hi';
import './sidebar.css';
import { Link, useNavigate } from 'react-router-dom';
import {AuthContext} from './AuthContext';
import { useContext } from 'react';

export default function MySidebar(props) {
  const { dispatch } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    console.log("Logout");
    dispatch({ type: "LOGOUT" });
    navigate("/");
    window.location.reload();
  };

  return (
    <Sidebar aria-label="Sidebar with multi-level dropdown example">
      <Sidebar.Items>
        <Sidebar.ItemGroup>
        <Link to='/news' style={{padding:'0px', margin:'0px'}}>
          <Sidebar.Item href="#" icon={HiNewspaper} style={{ margin:'2px'}} className={` w-100, ${props.loc == 'news' ? "active" : ""}`}>
            News & Blogs
            </Sidebar.Item>
            </Link>
          
            <Link to='/add_news' style={{padding:'0px', margin:'0px'}}>
          <Sidebar.Item  href="#" icon={HiPlusCircle} style={{ margin:'2px'}} className={` w-100, ${props.loc == 'add_news' ? "active" : ""}`}>
              Post News
              </Sidebar.Item>
              </Link>
              <Link to='/users' style={{padding:'0px', margin:'0px'}}>
          <Sidebar.Item  href="#" icon={HiUser} style={{ margin:'2px'}} className={` w-100, ${props.loc == 'users' ? "active" : ""}`}>
              Users
              </Sidebar.Item>
              </Link>  
              <Link to='/add_user' style={{padding:'0px', margin:'0px'}}>
          <Sidebar.Item  href="#" icon={HiUserAdd} style={{ margin:'2px'}} className={` w-100, ${props.loc == 'add_user' ? "active" : ""}`}>
              Add User
              </Sidebar.Item>
              </Link>  
              <Link to='/videos' style={{padding:'0px', margin:'0px'}}>
          <Sidebar.Item  href="#" icon={HiVideoCamera} style={{ margin:'2px'}} className={` w-100, ${props.loc == 'videos' ? "active" : ""}`}>
              Videos
              </Sidebar.Item>
              </Link>  
              <Link to='/add_video' style={{padding:'0px', margin:'0px'}}>
          <Sidebar.Item  href="#" icon={HiPlus} style={{ margin:'2px'}} className={` w-100, ${props.loc == 'add_video' ? "active" : ""}`}>
              Post Video
              </Sidebar.Item>
              </Link>  
          <Sidebar.Item  href="#" icon={HiLogout} style={{ margin:'2px'}} onClick={(e)=>{handleLogout()}}>
            Logout
          </Sidebar.Item>
        </Sidebar.ItemGroup>
      </Sidebar.Items>
    </Sidebar>
  );
}
