import React, { useState, useEffect } from "react";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "./firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { doc, serverTimestamp, updateDoc } from "firebase/firestore";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { deleteDoc } from "firebase/firestore";
import styled from "@emotion/styled";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { Table } from "flowbite-react";
import { Button, Modal } from "flowbite-react";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import Header from "./Header";
import MySidebar from "./MySidebar";

export default function User() {
  const [users, setUsers] = useState([]);
  const handleUpdate = async () => {
    const updateRef = doc(db, "user", usersData.id);
    await updateDoc(updateRef, {
      ...usersData,
      lastUpdated: serverTimestamp(),
    });
  };

  const handleDeleted = async () => {
    const deleteRef = doc(db, "user", id);
    await deleteDoc(deleteRef);
  };

  const [id, setId] = useState("");

  useEffect(() => {
    const q = query(collection(db, "user"));
    const unsub = onSnapshot(
      q,
      (snapShot) => {
        let list = [];
        snapShot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        list.sort((a, b) => parseFloat(a.timeStamp) - parseFloat(b.timeStamp));
        setUsers(list);
        console.log(list);
      },
      (error) => {
        console.log(error);
      }
    );
    return () => {
      unsub();
    };
  }, []);

  const [usersData, setUsersData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
  });
  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
  });

  const [openModalUpdate, setOpenModalUpdate] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);

  return (
    <div className="users-body">
      <Header loc="Users" />
      <div className="container">
      <div className="sidebar" >
      <MySidebar loc='users'/>
    </div>
    <div className="overflow-x-auto mt-4 users" style={{marginLeft:'10%', marginTop:'5%'}}>
      <Table style={{width:'60vw'}}>
        <Table.Head>
          <Table.HeadCell>First Name</Table.HeadCell>
          <Table.HeadCell>Last Name</Table.HeadCell>
          <Table.HeadCell>Email</Table.HeadCell>
          <Table.HeadCell>Edit</Table.HeadCell>
          <Table.HeadCell>Delete</Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          {users.map((user) => {
           return <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
              <Table.Cell>{user.first_name}</Table.Cell>
              <Table.Cell>{user.last_name}</Table.Cell>
              <Table.Cell>{user.email}</Table.Cell>
              <Table.Cell>
                <FontAwesomeIcon
                  icon={faEdit}
                  href="#"
                  className=" mt-4 mr-4 inline-flex items-center rounded-lg  px-4 py-2 text-center text-sm font-medium text-white hover:bg-emerald-800 hover:text-white-900 focus:outline-none focus:ring-4 focus:ring-cyan-300 dark:bg-cyan-600 dark:hover:bg-cyan-700 dark:focus:ring-cyan-800"
                  onClick={(e) => {
                    setUsersData(user);
                    setOpenModalUpdate(true);
                  }}
                />
              </Table.Cell>
              <Table.Cell>
                <FontAwesomeIcon
                  icon={faTrash}
                  className="mt-4 mr-4 inline-flex items-center rounded-lg  px-4 py-2 text-center text-sm font-medium text-white hover:bg-emerald-800 hover:text-white-900 focus:outline-none focus:ring-4 focus:ring-cyan-300 dark:bg-cyan-600 dark:hover:bg-cyan-700 dark:focus:ring-cyan-800"
                  onClick={(e) => {
                    setId(user.id);
                    setOpenModalDelete(true);
                  }}
                />
              </Table.Cell>
            </Table.Row>;
          })}
        </Table.Body>
      </Table>

      <Modal show={openModalUpdate} onClose={() => setOpenModalUpdate(false)}>
        <Modal.Header>Edit</Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            <center>
              <input
                className="w-80 mb-2 p-2 textfield"
                placeholder="First Name"
                id="outlined-size-small"
                size="small"
                type="text"
                value={usersData.first_name}
                onChange={(e) =>
                  setUsersData({ ...usersData, first_name: e.target.value })
                }
              />
            </center>
            <center>
              <input
                className="w-80 mb-2 p-2 textfield"
                placeholder="Last Name"
                value={usersData.last_name}
                id="outlined-size-small"
                size="small"
                type="text"
                onChange={(e) =>
                  setUsersData({ ...usersData, last_name: e.target.value })
                }
              />
            </center>
            <center>
              <input
                className="w-80 mb-2 p-2 textfield"
                value={usersData.email}
                placeholder="Email"
                id="outlined-size-small"
                size="small"
                type="email"
                onChange={(e) => setUsersData({ ...usersData, email: e.target.value })}
              />
            </center>
            <center>
              <input
                className="w-80 mb-2 p-2 textfield"
                placeholder="Password"
                value={usersData.password}
                id="outlined-size-small"
                size="small"
                onChange={(e) => setUsersData({ ...usersData, password: e.target.value })}
              />
            </center>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn"
            onClick={() => {
              handleUpdate();
              setOpenModalUpdate(false);
            }}
          >
            Save
          </Button>
          <Button
            className="btn"
            color="gray"
            onClick={() => setOpenModalUpdate(false)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={openModalDelete}
        size="md"
        onClose={() => setOpenModalDelete(false)}
        popup
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Are you sure you want to delete this product?
            </h3>
            <div className="flex justify-center gap-4">
              <Button
                color="failure"
                onClick={() => {
                  handleDeleted();
                  setOpenModalDelete(false);
                }}
              >
                {"Yes, I'm sure"}
              </Button>
              <Button
                color="gray"
                onClick={() => {
                  setOpenModalDelete(false);
                }}
              >
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
    </div>
    </div>
  );
}
