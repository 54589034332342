import { Route, Routes } from "react-router-dom";
import AddNews from "./AddNews";
import AddUser from "./AddUser";
import "./App.css";
import Header from "./Header";
import Login from "./Login";
import News from "./News";
import User from "./User";
import MoreNews from "./MoreNews";
import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { AuthContext } from "./AuthContext";
import Videos from "./Videos";
import AddVideos from "./AddVideos";

function App() {
  const { currentUser } = useContext(AuthContext);

  const RequireAuth = ({ children }) => {
    return currentUser ? children : <Navigate to="/" />;
  };

  

  return (
    <div className="App">
    <Routes>
            <Route basename={<Login />} path="/" element={<Login />} />
            <Route
              path="/news"
              element={
                <RequireAuth>
                  <News />
                </RequireAuth>
              }
            />
            <Route
              path="/add_news"
              element={
                <RequireAuth>
                  <AddNews />
                </RequireAuth>
              }
            />
            <Route
              path="/add_user"
              element={
                <RequireAuth>
                  <AddUser />
                </RequireAuth>
              }
            />
            <Route
              path="/users"
              element={
                <RequireAuth>
                  <User />
                </RequireAuth>
              }
            />
            <Route
              path="/videos"
              element={
                <RequireAuth>
                  <Videos />
                </RequireAuth>
              }
            />
            <Route
              path="/add_video"
              element={
                <RequireAuth>
                  <AddVideos />
                </RequireAuth>
              }
            />
            <Route
              exact
              path="/detail"
              element={
                <RequireAuth>
                  <MoreNews />
                </RequireAuth>
              }
            />
          </Routes>
      
    </div>
  );
}

export default App;

