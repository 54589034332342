import "./add_news.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import React, { useState, useEffect } from "react";
import { addDoc, serverTimestamp, collection } from "firebase/firestore";
import { db, storage } from "./firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { IonIcon } from "@ionic/react";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./add_news.css";
import { Alert, Button } from "flowbite-react";
import { HiInformationCircle } from "react-icons/hi";
import Header from "./Header";
import MySidebar from "./MySidebar";

const AddNews = () => {
  const [file, setFile] = useState("");
  const [per, setPerc] = useState(null);

  const [news, setNews] = useState({
    title: "",
    snippet: "",
    content: "",
    date: Date.now(),
  });

  const handleClear = () => {
    news.title = "";
    news.content = "";
    news.snippet = "";
  };

  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "link"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        [{ align: [] }],
        ["clean"],
        [{ color: [] }],
      ],
    },
  };
  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  const handleChange = (value) => {
    setNews({ ...news, content: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "news"), {
        ...news,
        timeStamp: serverTimestamp(),
      });
    } catch (error) {
      console.log("error :" + error.serverResponse);
      setOpenError(true);
    }
    handleClear();
    setOpenSuccess(true);
  };

  useEffect(() => {
    const uploadFile = () => {
      const name = new Date().getTime() + file.name;

      console.log(name);
      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setPerc(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setNews((prev) => ({ ...prev, img: downloadURL }));
          });
        }
      );
    };
    file && uploadFile();
    console.log("img");
  }, [file]);

  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);

  return (
    <div className=" add-news-body ">
     <Header loc="Post News" />
      <div className="container">
      <div className="sidebar" >
        <MySidebar loc='add_news'/>
      </div>
      <form action="" method="post">
      {openSuccess && (
        <Alert
          color="success"
          className="alert"
          style={{ width: "30%", height:'5vh' }}
          
        >
         <center>
            Data Added Successfully!
         </center>
        </Alert>
      )}

      {openError && (
        <Alert
          color="failure"
          className="alert"
          icon={HiInformationCircle}
          style={{ width: "30%", height:'5vh' }}
        >
         <center>
          Failed To Add!
         </center>
        </Alert>
      )}
        <div className="">
          <div>
            <center>
              <img
                style={{ width: "15%" }}
                src={
                  file
                    ? URL.createObjectURL(file)
                    : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                }
                alt=""
              />
              <label
                htmlFor="file"
                className="mt-4 border border-cyan-700 h-8 inline-flex items-center rounded-lg p-4"
              >
                <FontAwesomeIcon icon={faUpload} color="#4d7386" size="lg" />
              </label>
              <input
                type="file"
                id="file"
                onChange={(e) => setFile(e.target.files[0])}
                style={{ display: "none" }}
              />
            </center>
          </div>
        </div>
        <center>
          <input
            className="mb-2 p-2 title"
            placeholder="Title"
            id="outlined-size-small"
            size="small"
            onChange={(e) => setNews({ ...news, title: e.target.value })}
          />
        </center>
        <div>
          <textarea
            placeholder="Snippet"
            id="outlined-size-small"
            size="small"
            rows={2}
            style={{
              border: "1px solid grey",
              width: "37vw",
              height: "10vh",
              padding: "2%",
              marginBottom: "3%",
            }}
            value={news.snippet}
            onChange={(e) => setNews({ ...news, snippet: e.target.value })}
          />
        </div>
        <div>
          <center>
            <ReactQuill
              theme="snow"
              modules={modules}
              value={news.content}
              formats={formats}
              onChange={handleChange}
              style={{ width: "80vw !important"}}
            />
          </center>
        </div>
        <div className="row">
          <Button className="btn" onClick={handleSubmit}>
            Save
          </Button>
          
        </div>
      </form>

      
    </div>
    </div>
  );
};

export default AddNews;
