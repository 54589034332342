"use client";

import React, { useEffect, useState } from "react";
import "./videos.css";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { serverTimestamp } from "firebase/firestore";
import { db, storage } from "./firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { deleteDoc } from "firebase/firestore";
import { doc, updateDoc } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Modal } from "flowbite-react";
import { faUpload, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import ReactPlayer from "react-player";
import Header from "./Header";
import MySidebar from "./MySidebar";

const Videos = () => {
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const q = query(collection(db, "videos"));
    const unsub = onSnapshot(
      q,
      (snapShot) => {
        let list = [];
        snapShot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        list.sort((a, b) => parseFloat(a.timeStamp) - parseFloat(b.timeStamp));
        setVideos(list);
        console.log(list);
      },
      (error) => {
        console.log(error);
      },
      []
    );

    return () => {
      unsub();
    };
  }, []);

 

  const navigate = useNavigate();

 const handleNavigate = (path) =>{
   navigate(path)
 }

  const handleDeleted = async () => {
    const deleteRef = doc(db, "videos", id);
    await deleteDoc(deleteRef);
    handleClose_1();
  };

  const [id, setId] = useState("");

  const [data, setData] = useState({});
  const [per, setPerc] = useState(null);
  const [file, setFile] = useState("");

  
  const handleUpdate = async () => {
    const updateRef = doc(db, "videos", videosData.id);
    await updateDoc(updateRef, {
      ...videosData,
      lastUpdated: serverTimestamp(),
    });
    handleClose();
  };

 

  const handleChange = (value) => {
    setVideosData({ ...videosData, content: value });
  };


  const [openModalUpdate, setOpenModalUpdate] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);

  const [open, setOpen] = useState(false);
  const handleOpen = (row) => {
    setVideos(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [open_1, setOpen_1] = useState(false);

  const handleClose_1 = () => setOpen_1(false);
  const [videosData, setVideosData] = useState({
    link: "",
    description: ""
  });


  return (
    <div className="videos-body">
      <Header loc="Videos" />
      <div className="container">
        <div className="sidebar" >
          <MySidebar loc='videos'/>
        </div>
      <section className=" videos" aria-labelledby="videos-label">
        
        <ul className="grid-list bg-transparent" style={{width:'82.4vw'}}>
          {videos.map((n) => {
            return (
                <li key={n.id} style={{ width: "20vw" }}>
                <div className=" max-w-sm video-card" style={{backgroundColor:'white'}}>
                  <figure className="card-banner img-holder">
                    <ReactPlayer
                      url={n.link}
                      className="player"
                    />
                  </figure>
                  <div className="card-content">
                    <h3 className="h3" style={{height:'10vh', overflowY:'scroll'}}>
                      <a href="#" className="card-title">
                        {n.description}
                      </a>
                    </h3>
                    <a href="#" className="btn btn-outline" style={{color:'white'}} onClick={(e)=>{
                      handleNavigate(n.link)
                    }}>
                      Watch
                    </a>
                  </div>
                  <div
                        style={{ height: "5vh" }}
                        className="mt-4 flex space-x-4 lg:mt-6 "
                      >
                        <Button
                          style={{ bottom: "0" }}
                          onClick={(e) => {
                            setVideosData(n);
                            setOpenModalUpdate(true);
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          onClick={(e) => {
                            setId(n.id);
                            setOpenModalDelete(true);
                          }}
                        >
                          Delete
                        </Button>
                      </div>
                </div>
              </li>
            );
          })}
        </ul>
      </section>

      <Modal show={openModalUpdate} onClose={() => setOpenModalUpdate(false)}>
        <Modal.Header >Edit</Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
          <div>
          <input
            placeholder="Link"
            className="textfield"
            onChange={(e) => setVideosData({ ...videos, link: e.target.value })}
          />
        </div>
        <div>
          <textarea
            placeholder="Description"
            id="outlined-size-small"
            size="small"
            rows={2}
            style={{
              border: "1px solid grey",
              height: "10vh",
              padding: "2%",
              marginBottom: "3%",
            }}
            className="textfield"
            value={videos.description}
            onChange={(e) => setVideosData({ ...videos, description: e.target.value })}
          />
        </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="button"
            onClick={() => {
              handleUpdate();
              setOpenModalUpdate(false);
            }}
          >
            Save
          </Button>
          <Button
            className="button"
            color="gray"
            onClick={() => setOpenModalUpdate(false)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={openModalDelete}
        size="md"
        onClose={() => setOpenModalDelete(false)}
        popup
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Are you sure you want to delete this product?
            </h3>
            <div className="flex justify-center gap-4">
              <Button
                color="failure"
                onClick={() => {
                  handleDeleted();
                  setOpenModalDelete(false);
                }}
              >
                {"Yes, I'm sure"}
              </Button>
              <Button
                color="gray"
                onClick={() => {
                  setOpenModalDelete(false);
                }}
              >
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
    </div>
  );
};

export default Videos;
