import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./login.css";
import { AuthContext } from "./AuthContext.js";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "./firebase";

const Login = () => {
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const { dispatch } = useContext(AuthContext);

  const handleLogin = (e) => {
    e.preventDefault();
    console.log("email: " + email + " password: " + password);
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        console.log("user " + { ...user });
        dispatch({ type: "LOGIN", payload: user });
        navigate("/news");
      })
      .catch((error) => {
        console.log(error.message);
        setError(true);
      });
    setEmail("");
    setPassword("");
  };

  return (
    <div>
      <div className="container" id="container">
        
        <div className="form-container  sign-in">
          <form action="">
            <h1>Sign In</h1>
            <span>use your email & password</span>
            <input type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value.trim())} className="textfield"/>
            <input type="Password" placeholder="Password"  onChange={(e) => setPassword(e.target.value.trim())} className="textfield"/>
            <button className="" id="login" onClick={handleLogin}>
                Sign In
            </button>
            <a href="">{error && <span>Wrong email or password!</span>}</a>
          </form>
        </div>
        <div className="toggle-container form-left">
          <div className="toggle">
            <div className="toggle-panel toggle-left">
              <h1>Welcome Back!</h1>
              <p>Enter your personal details to use all of site features</p>
              
            </div>
            <div className="toggle-panel toggle-right">
              <h1 style={{color:'white'}}>Welcome Back!</h1>
              <p>Enter your personal details to use the site.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

